import { createSlice } from "@reduxjs/toolkit";

const getInitialUserData = () => {
  try {
    return JSON.parse(localStorage.getItem("userData"));
  } catch {
    return null;
  }
};

const initialState = {
  userData: getInitialUserData(),
  showNewXMsg: true
};

export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    refetchUserData: (state) => {
      state.userData = getInitialUserData();
    },
    toggleMsg: (state) => {
      state.showNewXMsg = !state.showNewXMsg;
    }
  }
});

export const { refetchUserData, toggleMsg } = userDataSlice.actions;
export default userDataSlice.reducer;
